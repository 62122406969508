<template>
  <div class="st-container">
    <v-select
      v-model="selected"
      :options="destinationOptions"
      dir="rtl"
      :clearable="false"
      :placeholder="$t('search-tab.choose-where')"
      @option:selecting="selecting"
      @search:focus="deselecting"
      class="style-chooser"
    >
    <template #selected-option="{ label }">
      <div style="display: flex; align-items: baseline;">
        <span style="margin-left:10px;">
          <i class="fa fa-plane" aria-hidden="true"></i>
        </span>
        {{ label }}
      </div>
    </template>
    <template v-slot:option="{ label }">
      <span style="margin-left:10px;font-size:1.5rem;">
          <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
      </span>
      {{ label }}
    </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import TemplateSearchSelect from './TemplateSearchSelect';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'SelectTheme2',
  mixins: [TemplateSearchSelect],
  components: {
    vSelect,
  },
};
</script>
<style scoped>
.st-container {
  overflow: visible;
}
</style>
<style>
input[type=search]{
  margin: 0;
  padding: 0;
  border: 0;
}
.st-container .vs--searchable .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: auto;
}
.v-select.vs--open.vs--searchable .vs__dropdown-toggle{
  border-radius: 25px 25px 0px 0px;
}
.vs__selected{
  width: max-content;
}
.style-chooser .vs__dropdown-menu li {
  height: 50px;
  line-height: 35px;
  transition: 0.2s;
}
.vs__dropdown-toggle{
  background-color: #e9ecef!important;
}
.vs__dropdown-option {
    color: #333 !important;
}

</style>
